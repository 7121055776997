import React, { useState } from "react"

import "./contact-and-address-tile.sass"
import SocialMediaIcons from "../social-media-icons/social-media-icons"
import FormElement from "../form-element/form-element"
import classNames from 'classnames'
import trackCustomEvent from "../../util/track-custom-event"
import { WebformComponent, Input, Textarea, ErrorMessage } from "@brynlabs/webforms-react"

const ContactAndAddressTile = () => {
  const [success, setSuccess] = useState(false);

  return (
    <section>
      <div className={"_13"}>
        <div className={"_14"}>


          <div className="_75">
            <div className={"_76"}>

              <h3 className={"_4d _77"}>Kontakt & Anfahrt</h3>
              <div className={"_7b"}>
                <div className={"_7c"}>

                  <div className={"_ar _4i"}>Fahrschule
                    Rauscher
                  </div>
                  <p><a className={"_4n"} href="https://goo.gl/maps/gq9zfDeupcprzLPJ6" target={"_blank"}>Aspacher
                    Straße 53<br/>71522 Backnang</a></p>
                  <p><a className={"_4n"}
                        href="mailto:info@fahrschulerauscher.de">info@fahrschulerauscher.de</a><br/><a
                    className={"_4n"}
                    href="tel:+491732717781">0173 2717 781</a></p>
                </div>
                <div className={"_7d"}>

                  <div
                    className={"_as _4i"}>Öffnungszeiten
                  </div>
                  <p>Mittwoch 18:00 - 19:00 und nach individueller Vereinbarung</p>
                </div>
                <SocialMediaIcons small></SocialMediaIcons>
              </div>
              <div
                className={classNames("_78", { "_7a": success })}>
                <div className={"_79"}>
                  <h4 className={"_4g"}>Nachricht gesendet!</h4>
                  <p>🎉</p>
                </div>

                <WebformComponent
                  onSuccess={() => {
                    setSuccess(true);
                    // if (process.env.GATSBY_TEST_WEBFORMS !== "true") {
                    //   trackCustomEvent({
                    //     // string - required - The object that was interacted with (e.g.video)
                    //     category: "KontaktFormular",
                    //     // string - required - Type of interaction (e.g. 'play')
                    //     action: "Gesendet",
                    //   });
                    // }
                  }}
                  onError={() => {
                    setSuccess(false)
                  }}
                  recaptchaPublicKey={"6LeZersZAAAAAEw_AainBc9HRnevOWZAIVC-_iJw"}
                  formToken={
                    "DiIKRIGWgtlgfdeLTxkZJROryoGRcxQenpgaIKanXAftjJSxMldJgouyTaNSLkYc"
                  }
                >

                  <FormElement>
                    <label htmlFor="name">Name</label>
                    <Input id={"name"} webformsKey={'name'} type={"text"} required={true}/>
                    <ErrorMessage className={'_at'} webformsKey={"name"}>Bitte gib deinen Namen ein</ErrorMessage>
                  </FormElement>
                  <FormElement>
                    <label htmlFor="email">E-Mail-Adresse</label>
                    <Input id={"email"} webformsKey={'email'} type="email" required={true} />
                    <ErrorMessage className={'_at'} webformsKey={"email"}>Bitte gib eine gültige E-Mail Adresse ein</ErrorMessage>
                  </FormElement>
                  <FormElement>
                    <label htmlFor="message">Deine Nachricht</label>
                    <Textarea rows={4} id={"message"} webformsKey={'message'} type="text" required={true} />
                    <ErrorMessage className={'_at'} webformsKey={"message"}>Gib hier deine Nachricht ein</ErrorMessage>
                  </FormElement>

                  <button type={'submit'} className={'_4t'}>Nachricht senden</button>

                  <div className="recaptcha">
                    This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                  </div>

                </WebformComponent>

              </div>
            </div>
            <p className={"_7e"}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2620.3027436806665!2d9.421557316081785!3d48.94772097929638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4799b4dfd2686f7d%3A0xd57a830600593967!2sFahrschule%20Rauscher!5e0!3m2!1sen!2sde!4v1614024786249!5m2!1sen!2sde"
                height={"100%"} width={"100%"} allowFullScreen="" loading="lazy"></iframe>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactAndAddressTile
