import React from "react"
import classNames from 'classnames'
import PropTypes from 'prop-types'

import "./form-element.sass"

const FormElement = ({children, className, type}) => (
  <div className={classNames('_au', className, {'_7i': type === 'checkbox'}, {'_7f': type === 'default'})}>{children}</div>
)

FormElement.propTypes = {
  type: PropTypes.oneOf(['default', 'checkbox']),
}

FormElement.defaultProps = {
  type: 'default',
}

export default FormElement
